var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { ReactShakaPlayer } from '@mkhuda/react-shaka-player';
import { WebVTTParser } from 'webvtt-parser';
import { Text } from '@zaber/react-library';
import moment from 'moment';
export const VideoPlayer = ({ videoUrl, captionsUrl, chaptersUrl }) => {
    const [mainPlayer, setMainPlayer] = useState(null);
    useEffect(() => {
        if (!mainPlayer) {
            return;
        }
        const { player, videoElement } = mainPlayer;
        function load() {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    yield player.load(videoUrl);
                }
                catch (err) {
                    // eslint-disable-next-line no-console
                    console.error('Error loading video:', err);
                }
                if (captionsUrl) {
                    try {
                        yield player.addTextTrackAsync(captionsUrl, 'en', 'captions');
                    }
                    catch (err) {
                        // eslint-disable-next-line no-console
                        console.error('Error loading captions:', err);
                    }
                }
                if (chaptersUrl) {
                    try {
                        yield player.addChaptersTrack(chaptersUrl, 'en');
                    }
                    catch (err) {
                        // eslint-disable-next-line no-console
                        console.error('Error loading chapters:', err, JSON.stringify(err, null, 2));
                    }
                }
            });
        }
        if (player && videoElement) {
            void load();
        }
    }, [mainPlayer]);
    const [chapters, setChapters] = useState(null);
    useEffect(() => {
        if (!chaptersUrl) {
            return;
        }
        setTimeout(() => {
            fetch(chaptersUrl, { mode: 'cors' })
                .then(response => response.text())
                .then(response => {
                const parser = new WebVTTParser();
                const chapters = parser.parse(response);
                setChapters(chapters);
            })
                // eslint-disable-next-line no-console
                .catch(error => console.error('Error parsing chapter list', error));
        }, 500);
    }, [chaptersUrl]);
    const seek = (time) => {
        if ((mainPlayer === null || mainPlayer === void 0 ? void 0 : mainPlayer.videoElement) && mainPlayer.player) {
            mainPlayer.videoElement.currentTime = mainPlayer.player.seekRange().start + time;
        }
    };
    return _jsxs("div", Object.assign({ className: "video-player" }, { children: [_jsx(ReactShakaPlayer, { className: "amsterdam-acid-red", config: {
                    streaming: {
                        bufferingGoal: 30,
                        rebufferingGoal: 15,
                        bufferBehind: 30,
                        retryParameters: {
                            maxAttempts: 3,
                            baseDelay: 500,
                            backoffFactor: 2,
                        },
                        failureCallback: (error) => {
                            // eslint-disable-next-line no-console
                            console.warn('Player error:', error);
                        }
                    }
                }, uiConfig: {
                    //controlPanelElements: ['play_pause', 'time_and_duration', 'volume', 'chapter', 'fullscreen', 'overflow_menu'],
                    overflowMenuButtons: ['quality', 'captions', 'picture_in_picture', 'playback_rate'],
                    seekBarColors: {
                        base: 'rgba(167, 25, 28, 0.5)',
                        buffered: 'rgba(167, 25, 28, 0.7)',
                        played: 'rgba(211, 32, 36, 0.8)',
                    },
                    volumeBarColors: {
                        base: 'rgba(167, 25, 28, 0.8)',
                        level: 'rgb(211, 32, 36)',
                    }
                }, onLoad: player => setMainPlayer(player) }, void 0), chapters && _jsxs("div", Object.assign({ className: "chapters" }, { children: [_jsx(Text, Object.assign({ e: Text.Emphasis.Bold, t: Text.Type.BodyLg }, { children: "Chapters" }), void 0), chapters.cues.map((cue, i) => _jsxs(_Fragment, { children: [_jsx(Text, Object.assign({ appearsClickable: true, onClick: () => seek(cue.startTime) }, { children: moment.utc(moment.duration(cue.startTime, 'seconds').asMilliseconds()).format('mm:ss') }), 2 * i), _jsxs(Text, { children: [": ", cue.text] }, 2 * i + 1)] }, void 0))] }), void 0)] }), void 0);
};
